import '@babel/polyfill';
import createCommonHeader from '@js/common/createCommonHeader';
import createScrollToTopButton from '@js/common/createScrollToTopButton';

/**
 * 共通系の処理を実行する
 */
const commonFunc = () => {
  // 共通ヘッダーのDOMを生成
  createCommonHeader();

  // トップへスクロールするボタンのDOMを生成
  createScrollToTopButton();
};

document.addEventListener('DOMContentLoaded', commonFunc);
