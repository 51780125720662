<template lang="pug">
  transition(name="fade")
    div(v-if="show" v-bind:class="{mk}")
      button(type="button", v-on:click="execScroll")
        span
</template>

<style lang="scss" scoped>
  div {
    position: sticky;
    position: -webkit-sticky;
    bottom: 20px;
    right: 0;

    &.mk {
      position: fixed;
    }

    @include mq() {
      bottom: 40px;
    }

    button {
      display: block;
      width: mod_vw(90);
      height: mod_vw(90);
      background: map-get($colors, 'main');
      box-shadow: 0px mod_vw(6) mod_vw(12) 0 rgba(map-get($colors, 'main'), 0.3);
      border-radius: 50%;
      position: absolute;
      bottom: 60px;
      right: 20px;
      -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
      -webkit-touch-callout: none;
      user-select: none;

      @include mq() {
        width: 60px;
        height: 60px;
        box-shadow: 0px 3px 5.64px 0.36px rgba(map-get($colors, 'main'), 0.3);

        &:after {
          content: '';
          display: block;
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          border-radius: 50%;
          background: #fff;
          opacity: 0;
          transition: opacity 0.2s ease 0s;
        }

        &:hover:after {
          opacity: 0.3;
        }
      }

      &:before {
        content: '';
        display: block;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 mod_vw(10) mod_vw(10);
        border-color: transparent transparent #fff transparent;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);

        @include mq() {
          border-width: 0 7px 7px;
        }
      }
    }
  }

  // IE hack
  ::-ms-backdrop, :root div{
    position: fixed;
  }

  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.2s ease 0s;
  }

  .fade-enter,
  .fade-leave-to {
    opacity: 0;
  }
</style>

<script>
import Vue from 'vue';
import vueSmoothScroll from 'vue-smoothscroll';

Vue.use(vueSmoothScroll);

export default Vue.extend({
  // props: ['threshold'],
  data: function() {
    return {
      // 出現フラグ
      show: false,
      // ボタンが出現するスクロール位置
      threshold: 0,
      // 埋め込みコンテンツの判定
      mk: location.host.indexOf('contents') >= 0
    }
  },
  methods: {
    // スクロールイベントを設定
    setScrollEvent: function() {
      let scrollTicking = false;
      if(scrollTicking) return false;
      document.addEventListener('scroll', e => {
        requestAnimationFrame(e => {
          this.toggleExists();
          scrollTicking = true;
        });
      }, {
        passive: true,
      });
    },
    // ナビゲーション自体の表示／非表示切替
    // this.$data.sections[0]よりもスクロールトップが下のときのみナビゲーションを表示させる
    toggleExists: function() {
      const currentTop = window.pageYOffset;
      this.$data.show = currentTop >= this.$data.threshold;
    },
    // スムーススクロール実行
    execScroll: function() {
      this.$SmoothScroll(0, 500);
    }
  },
  mounted: function() {
    this.setScrollEvent();
    this.toggleExists();
  }
});
</script>
