/**
 * 共通ヘッダーメニューを生成する
 */
import Vue from 'vue';

import CommonHeader from '@vue/common/CommonHeader';
Vue.component('common-header', CommonHeader);

export default () => {
  new Vue({
    el: '#v-common_header',
    props: ['imgSrc']
  });
};
