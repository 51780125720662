/**
 * 共通ヘッダーメニューを生成する
 */
import ScrollToTopButton from "@vue/common/ScrollToTopButton";

const SELECTOR = "#v-scroll_to_top_button";

export default () => {
  //
  if (!document.querySelector(SELECTOR)) {
    return;
  }

  new ScrollToTopButton({
    data: function () {
      return {
        threshold: 100, // ボタンが出現するスクロール位置
      };
    },
  }).$mount(SELECTOR);
};
