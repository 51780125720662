var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("header", { staticClass: "is-default" }, [
    _c(
      "div",
      { staticClass: "inr" },
      [
        _c("h1", { staticClass: "logo" }, [
          _c("a", { attrs: { href: _vm.navItems[0].href } }, [
            _c("img", {
              staticClass: "is-default",
              attrs: {
                src: _vm.imgPath + "img-logo--white.png",
                alt: "竹田・菊地税理士法人"
              },
              on: { load: _vm.setCommonHeaderHeight }
            }),
            _c("img", {
              staticClass: "is-scrolled",
              attrs: {
                src: _vm.imgPath + "img-logo--blue.png",
                alt: "竹田・菊地税理士法人"
              },
              on: { load: _vm.setCommonHeaderHeight }
            })
          ])
        ]),
        _c("hamburger-menu", {
          attrs: {
            items: _vm.navItems,
            offset: _vm.ownHeight,
            "current-view-mode": _vm.currentViewMode,
            "contact-href": _vm.contactHref,
            isScrolled: _vm.isScrolled,
            isDefault: _vm.isDefault
          }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }