<!-- ハンバーガーメニュー用コンポーネント -->

<template lang="pug">
  nav.is-default(:class="[isScrolled && 'is-scrolled']")
    div.inr(
      :class="[active && 'is-active', currentViewMode === 'sp' && 'is-sp_view']",
      :style="[active && {marginTop:`${offset}px`}, {height: `calc(100% - ${offset}px)`}]"
    )
      ul
        li(
          v-for="item in items"
          :class="item.child && 'is-wrap'"
        )
          a(
            :href="item.href",
            @click="toggleActive(); execNavigation(item.href, $event);",
            v-if="!item.children"
          ) {{ item.label }}
          div.nav-list(v-else)
            span {{ item.label }}
            ul
              li(v-for="navItem in item.children")
                a(
                  :href="navItem.href",
                  @click="toggleActive(); execNavigation(navItem.href, $event);"
                ) {{ navItem.label }}
      p
        a(:href="contactHref",
          @click="toggleActive(); execNavigation('/contact', $event);")
          span.is-icon.u-sp-if
            i.icon.icon-mail-blue
          span
            | {{ currentViewMode === 'pc' ? 'お問い合わせ' : 'お問い合わせフォーム' }}
    button(type="button",
      @click="toggleActive"
      :class="{'is-active':active}")
      span
</template>

<style lang="scss" scoped>
nav {
  display: inline-block;

  &.is-scrolled, &.is-default {
    .inr {
      & > ul {
        & >li {
          > * {
            @include mq() {
              color: #2758ab;
            }
          }

          > .nav-list {
            > span {
              &::before {
                @include mq() {
                  color: #2758ab;
                }
              }
            }
          }
        }
      }

      p {
        a {
          @include mq() {
            background: linear-gradient(to right, #24b2c0 0%,#41bf8a 100%);
          }
        }
      }
    }
  }

  .inr {
    overflow: scroll;
    width: 100%;
    margin-top: 0;
    padding: 0 0 mod_vw(68);
    background: #fff;
    box-sizing: border-box;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9100;
    transform: translateY(-100%); // 自身の高さ+iOSなどで下に引っ張られたときのオフセット

    @include mq() {
      background-color: transparent;
      overflow: visible;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      height: auto !important;
      margin-top: 0 !important;
      padding: 0;
      position: static;
      transform: none;
      transition: none;
    }

    &.is-sp_view {
      transition: transform 0.5s ease 0s;
    }

    &.is-active {
      transform: translateY(0);

      @include mq() {
        transform: none;
      }
    }

    & > ul {
      width: 100%;

      @include mq() {
        display: flex;
        justify-content: flex-end;
        width: auto;
        margin-right: 15px;
      }

      & > li {
        &:not(:last-child) {
          @include mq() {
            margin-right: 5px;
          }
        }

        > * {
          display: block;
          font-size: mod_vw(30);
          letter-spacing: 0.1em;
          color: #2758ab;
          border-bottom: mod_vw(2) solid #2758ab;
          box-sizing: border-box;

          @include mq() {
            font-weight: 700;
            text-align: center;
            letter-spacing: 0;
            display: inline-block;
            padding: 33px 15px;
            font-size: 1.4rem;
            line-height: 1.0;
            color: #fff;
            border: 0;
            position: relative;

            &:after {
              content: '';
              display: block;
              width: 100%;
              height: 5px;
              background: linear-gradient(to right, #41bf8a 0%,#24b2c0 100%);
              position: absolute;
              bottom: 0;
              left: 0;
              transform: scaleX(0);
              transition: transform 0.2s ease 0s;
            }
          }

          &:hover {
            &:after {
              @include mq() {
                transform: scaleX(1.0);
              }
            }
          }
        }

        & > a {
          padding: mod_vw(28) mod_vw(30);

          @include mq() {
            padding: 33px 15px;
          }
        }

        & > .nav-list {
          position: relative;

          & > span {
            position: relative;
            box-sizing: border-box;
            display: inline-block;
            width: 100%;
            padding: mod_vw(28) mod_vw(30);

            @include mq() {
              padding: 0 16px 0 0;
            }

            &::before {
              content: "+";
              position: absolute;
              right: mod_vw(40);
              top: 50%;
              transform: translateY(-50%);
              color: #2758ab;
              font-weight: 700;
              font-size: mod_vw(44);

              @include mq() {
                color: #fff;
                right: 0;
                font-size: 1.6rem;
              }
            }
          }

          & > ul {
            @include mq() {
              position: absolute;
              bottom: 0;
              left: 0;
              transform: translateY(100%);
              visibility: hidden;
              opacity: 0;
              transition: opacity 0.25s ease 0s;
            }

            & > li {
              & > a {
                position: relative;
                display: inline-block;
                width: 100%;
                height: 100%;
                color: #fff;
                background: linear-gradient(to right, #41bf8a 0%,#24b2c0 100%);
                white-space: nowrap;
                text-align: left;
                box-sizing: border-box;
                padding: mod_vw(30) mod_vw(46);
                font-size: mod_vw(24);

                @include mq() {
                  padding: 0 20px;
                  min-width: 220px;
                  font-size: 1.6rem;
                  line-height: 2.6;
                }

                &:hover {
                  @include mq() {
                    text-decoration: underline;
                  }
                }
              }

              &:not(:last-child) {
                margin-bottom: mod_vw(2);

                @include mq() {
                  margin-bottom: 0;
                }
              }
            }
          }

          &:hover {
            & > ul {
              @include mq() {
                opacity: 1;
                visibility: visible;
              }
            }
          }
        }

        &:first-child a {
          border-top: mod_vw(2) solid #2758ab;

          @include mq() {
            border-top: 0;
          }
        }
      }

      &:not(:last-child) {
        margin-bottom: mod_vw(65);

        @include mq() {
          margin-bottom: 0;
        }
      }
    }

    p {
      text-align: center;

      .is-icon {
        position: relative;

        &:not(:last-child) {
          padding-right: mod_vw(27);
          margin-right: mod_vw(20);
        }

        &::before {
          content: "";
          position: absolute;
          top: 0;
          right: 0;
          height: 100%;
          background-color: #2758ab;
          width: mod_vw(2);
        }
      }

      a {
        @extend %extend-common_hover;
        display: inline-flex;
        align-items: center;
        padding: mod_vw(45) mod_vw(58) mod_vw(45) mod_vw(60);
        font-size: mod_vw(30);
        background: transparent;
        line-height: 1;
        color: #2758ab;
        text-align: center;
        box-sizing: border-box;
        border: mod_vw(2) solid #2758ab;

        @include mq() {
          border: 1px solid #fff;
          color: #fff;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 180px;
          height: 55px;
          padding: 0;
          font-size: 1.4rem;
          font-weight: 700;
        }
      }
    }
  }

  /* 表示切り替え用のボタン */
  button {
    width: mod_vw(46);
    height: mod_vw(32);
    border: mod_vw(10) solid transparent;
    position: relative;
    z-index: 9200;
    box-sizing: content-box;

    @include mq() {
      display: none;
    }

    span,
    &:before,
    &:after {
      content: '';
      display: block;
      width: 100%;
      height: mod_vw(4);
      background-color: #2758ab;
      position: absolute;
      transition: all 0.2s ease 0s;
      transform-origin: center;
    }

    span {
      margin-top: mod_vw(-2);
      top: 50%;
      transform: scaleX(1.0);
    }

    &:before {
      top: 0%;
    }

    &:after {
      bottom: 0%;
    }

    &.is-active {
      span {
        transform: scaleX(0);
      }

      &:before {
        margin-top: mod_vw(-2);
        top: 50%;
        transform: rotate(45deg);
      }

      &:after {
        margin-bottom: mod_vw(-2);
        bottom: 50%;
        transform: rotate(-45deg);
      }
    }
  }
}

_::-ms-backdrop, :root nav .inr ul li:nth-child(3):before{
  top: 61%;
}
</style>

<script>
import Vue from 'vue';
import vueSmoothScroll from 'vue-smoothscroll';

Vue.use(vueSmoothScroll);

export default {
  props: ['items', 'offset', 'currentViewMode', 'contactHref', 'isScrolled', 'isDefault'],
  data: function() {
    return {
      active: false,
    }
  },
  methods: {
    toggleActive: function() {
      this.$data.active = !this.$data.active;
    },
    // スムーススクロール実行
    execSmoothScroll: function(target) {
      this.$SmoothScroll(target, 500);
    },
    // リンククリック
    execNavigation: function(href, event) {
      const splittedHref = href.split(/\#/);

      // 同一ページかつsplitした結果の配列が複数存在する場合のみスクロールさせる
      if(href.split(/\#/)[0] === location.pathname && splittedHref.length > 1) {
        event.preventDefault();
        const target = document.getElementById(splittedHref.slice(-1)[0]);
        target && this.execSmoothScroll(target);
      }
    }
  }
};
</script>
