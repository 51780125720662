var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "nav",
    { staticClass: "is-default", class: [_vm.isScrolled && "is-scrolled"] },
    [
      _c(
        "div",
        {
          staticClass: "inr",
          class: [
            _vm.active && "is-active",
            _vm.currentViewMode === "sp" && "is-sp_view"
          ],
          style: [
            _vm.active && { marginTop: _vm.offset + "px" },
            { height: "calc(100% - " + _vm.offset + "px)" }
          ]
        },
        [
          _c(
            "ul",
            _vm._l(_vm.items, function(item) {
              return _c("li", { class: item.child && "is-wrap" }, [
                !item.children
                  ? _c(
                      "a",
                      {
                        attrs: { href: item.href },
                        on: {
                          click: function($event) {
                            _vm.toggleActive()
                            _vm.execNavigation(item.href, $event)
                          }
                        }
                      },
                      [_vm._v(_vm._s(item.label))]
                    )
                  : _c("div", { staticClass: "nav-list" }, [
                      _c("span", [_vm._v(_vm._s(item.label))]),
                      _c(
                        "ul",
                        _vm._l(item.children, function(navItem) {
                          return _c("li", [
                            _c(
                              "a",
                              {
                                attrs: { href: navItem.href },
                                on: {
                                  click: function($event) {
                                    _vm.toggleActive()
                                    _vm.execNavigation(navItem.href, $event)
                                  }
                                }
                              },
                              [_vm._v(_vm._s(navItem.label))]
                            )
                          ])
                        }),
                        0
                      )
                    ])
              ])
            }),
            0
          ),
          _c("p", [
            _c(
              "a",
              {
                attrs: { href: _vm.contactHref },
                on: {
                  click: function($event) {
                    _vm.toggleActive()
                    _vm.execNavigation("/contact", $event)
                  }
                }
              },
              [
                _vm._m(0),
                _c("span", [
                  _vm._v(
                    _vm._s(
                      _vm.currentViewMode === "pc"
                        ? "お問い合わせ"
                        : "お問い合わせフォーム"
                    )
                  )
                ])
              ]
            )
          ])
        ]
      ),
      _c(
        "button",
        {
          class: { "is-active": _vm.active },
          attrs: { type: "button" },
          on: { click: _vm.toggleActive }
        },
        [_c("span")]
      )
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "is-icon u-sp-if" }, [
      _c("i", { staticClass: "icon icon-mail-blue" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }