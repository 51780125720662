<!--
共通ヘッダーコンポーネント
-->

<template lang="pug">
  header.is-default
    div.inr
      h1.logo
        a(:href="navItems[0].href")
          //- ロゴデータが読み込まれた段階で自身の高さを取得し#v-common_headerの高さとして設定する
          img.is-default(:src="imgPath + 'img-logo--white.png'", alt="竹田・菊地税理士法人", @load="setCommonHeaderHeight")
          img.is-scrolled(:src="imgPath + 'img-logo--blue.png'", alt="竹田・菊地税理士法人", @load="setCommonHeaderHeight")
      hamburger-menu(:items="navItems", :offset="ownHeight", :current-view-mode="currentViewMode" :contact-href="contactHref", :isScrolled="isScrolled" :isDefault="isDefault")
</template>

<style lang="scss" scoped>
  header {
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99999;
    background-color: #fff;
    transition: background 0.25s ease 0s;

    @include mq() {
      background-color: transparent;
    }

    &.is-scrolled, &.is-default {
      @include mq() {
        background-color: #fff;
      }

      .logo {
        a {
          img {
            &.is-scrolled {
              @include mq() {
                opacity: 1;
              }
            }

            &.is-default {
              @include mq() {
                opacity: 0;
              }
            }
          }
        }
      }
    }

    .inr {
      align-items: center;
      display: flex;
      justify-content: space-between;
      margin: 0 auto;
      width: 100%;
      padding: mod_vw(20) mod_vw(20) mod_vw(20) mod_vw(30);
      box-sizing: border-box;

      @include mq() {
        min-width: 1320px;
        max-width: 1560px;
        padding: 0 50px;
      }
    }

    .logo {
      a {
        width: mod_vw(360);
        height: mod_vw(27);
        display: inline-block;
        position: relative;

        @include mq() {
          width: 202px;
          height: 32px;
        }

        img {
          position: absolute;
          top: 0;
          left: 0;
          transition: opacity 0.25s ease 0s;

          &.is-default {
            opacity: 0;
            @include mq() {
              opacity: 1;
            }
          }

          &.is-scrolled {
            opacity: 1;
            @include mq() {
              opacity: 0;
            }
          }
        }
      }
    }
  }
</style>

<script>
import Vue from 'vue';
import windowResize from 'window-resize';

// handmade utilities
import getViewMode from '@js/utils/getViewMode';

// vue components
import HamburgerMenu from '@vue/common/HamburgerMenu';
Vue.component('hamburger-menu', HamburgerMenu);

export default {
  // ロゴのパスは動的に変わるのでpropsとして受け取る
  props: ['imgPath', 'navItems', 'contactHref'],
  data: function() {
    return {
      currentViewMode: getViewMode(),
      ownHeight: 0,
      // スクロール位置の調整フラグ
      adjustScrollPosition: false,
      isScrolled: false,
      isDefault: false
    }
  },
  methods: {
    // 自身の高さを返す
    getCommonHeaderHeight: function() {
      return this.$el.clientHeight;
    },
    // position fixedするのでヘッダーの分の高さを確保する
    // 今回はVueインスタンスでもある親要素のheightを変更している
    setCommonHeaderHeight: function() {
      this.ownHeight = this.getCommonHeaderHeight();
      if (this.currentViewMode === 'sp') {
        this.$parent.$el.style.height = `${this.ownHeight}px`;
      }

      // CSS変数に格納
      document.documentElement.style.setProperty('--header-height', `${this.ownHeight}px`)
    },
    // イベントリスナの登録
    registerEventListeners: function() {
      windowResize.add(() => {
        const targetDOM = document.querySelector('#v-header-padding-target')
        const changedViewMode = getViewMode();
        this.isDefault = false;

        this.currentViewMode = changedViewMode;
        if (this.currentViewMode === 'sp') {
          this.setCommonHeaderHeight();
          if (targetDOM) targetDOM.style.paddingTop = '0px';
        } else if (targetDOM && getViewMode() === 'pc') {
          this.isDefault = true;
          targetDOM.style.paddingTop = `${this.getCommonHeaderHeight()}px`;
          this.$parent.$el.style.height = '0px';
        } else {
          if(targetDOM) targetDOM.style.paddingTop = '0px';
          this.$parent.$el.style.height = '0px';
        }
      });
      const targetDOM = document.querySelector('#v-header-padding-target')

      if (getViewMode() === 'sp') {
        this.setCommonHeaderHeight();
      } else if (targetDOM && getViewMode() === 'pc') {
        this.isDefault = true;
        targetDOM.style.paddingTop = `${this.getCommonHeaderHeight()}px`;
      }

      window.addEventListener('scroll', e => {
        window.setTimeout(() => {
          // スクロール位置の調整が終わっていなかったら実行する
          if(!this.$data.adjustScrollPosition) {
            window.scrollBy(0, -this.$data.ownHeight);
            this.$data.adjustScrollPosition = true;
          }
        }, 0);
      });
    }
  },
  mounted: function() {
    this.registerEventListeners();

    // // スクロール状況に応じてヘッダーのスタイル変更
    // const observer = new IntersectionObserver((entries) => {
    //   this.isScrolled = entries[0].isIntersecting
    // }, {
    //   root: null,
    //   rootMargin: '0px 0px -100%',
    //   threshold: 0
    // });
    // observer.observe(document.querySelector('#v-intersection'))
  },
  beforeDestroy: function() {
    windowResize.clear();
  }
};
</script>
